import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';
import { setRem, resetRem } from '../utils/rem.js';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      setRem: true
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/index.vue')
  },
  {
    path: '/privacy-20221028',
    name: 'privacy-20221028',
    component: () => import('@/views/privacy/privacy-20221028.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/terms/index.vue')
  },
  {
    path: '/terms-20221028',
    name: 'terms-20221028',
    component: () => import('@/views/terms/terms-20221028.vue')
  },
  {
    path: '/terms-20240517',
    name: 'terms-20240517',
    component: () => import('@/views/terms/terms-20240517.vue')
  },
  {
    path: '/terms-20241220',
    name: 'terms-20241220',
    component: () => import('@/views/terms/terms-20241220.vue')
  },
  {
    path: '/copyright-dmca',
    name: 'copyright-dmca',
    component: () => import('@/views/copyright-dmca/index.vue')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.meta.setRem) {
    setRem();
    window.addEventListener('resize', setRem);
  } else {
    resetRem();
    window.removeEventListener('resize', setRem);
  }
  next();
})

export default router;
